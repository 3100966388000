import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../components/Buttons/PurpleSolidButton"

import PikselFs from "../components/fullscreen/PikselFs"
import Seo from "../components/Seo"
import OluPikselGaleri from "../contents/Gallery/OluPikselGaleri"
import EndIndicatorTr from "../contents/global/tr/EndIndicatorTr"
import StartIndicatorTr from "../contents/global/tr/StartIndicatorTr"
import SvgLeftIcon from "../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../contents/SVG/SvgPixelTest"
const OluPikselTesti = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        title="Ölü ve Sıkışmış Piksel Testi"
        description="Herhangi bir kurulum yapmadan ücretsiz site üzerinden android, iphone telefonlarınızda, tablet, pc, akıllı televizyon, monitör ve bilgisayar ekranınızda ölü piksel ve sıkışmış piksel testi yapın."
      />
      <h1 className="text-center md:text-left">
        Ölü Piksel ya da Sıkışmış Piksel Testi
      </h1>
      <p className="lead">
        Herhangi bir kurulum yapmanıza gerek kalmadan aşağıdaki{" "}
        <code>Ölü - Sıkışmış Piksel Testi Yap</code> düğmesine tıklayarak varsa
        ekranınızdaki <strong>ölü pikselleri</strong> ya da{" "}
        <span className="font-bold text-purple-600">sıkışmış pikselleri</span>{" "}
        tespit edebilirsiniz.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Ölü - Takılmış Piksel testine başlamadan önce dikkat etmeniz
          gerekenler:
        </p>
        <p className="">
          Ölü piksel testini yapmadan önce ekranınızın temiz olduğundan ve
          bulunduğunuz ortamın aşırı aydınlık olmadığından emin olunuz.
          Ekranınızdaki tozlanma sizi yanıltabilirken, aşırı aydınlık ortam ölü
          pikselleri gözden kaçırmanıza sebebiyet verebilir.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Ölü - Sıkışmış Piksel Testi Yap"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2>
        Ölü Piksel ya da Sıkışmış (Takılmış) Piksel Testini Nasıl Yapabilirim?
      </h2>
      <p>
        Yukarıdaki <code>Ölü - Sıkışmış Piksel Testi Yap</code> düğmesine tıkladıktan sonra
        site tam ekrana geçecektir. Açılan ekranda bilgilendirme mevcuttur.
      </p>
      <p>
        <strong>Ölü pikselleri</strong> ya da{" "}
        <span className="font-bold text-purple-600">sıkışmış pikselleri</span>{" "}
        tespit etmek için tam ekranı kaplayan kırmızı, yeşil, mavi, siyah ve
        beyaz renkleri tek tek kontrol etmeniz gerekmektedir.
      </p>
      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        Tam ekranda iken renkler arasında geçiş yapmak için ekranın herhangi bir
        yerine farenizin sol tuşuyla tıklayabilir ya da klavyenizin{" "}
        <SvgLeftIcon className="inline text-purple-700 fill-current" /> ve{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" />{" "}
        tuşlarına (sol ve sağ) basabilirsiniz.
      </p>
      <p>
        Karşınıza çıkan renkleri yukarıdaki{" "}
        <code>
          Ölü piksel testine başlamadan önce dikkat etmeniz gerekenler
        </code>{" "}
        uyarısını dikkate alarak gözünüzle kontrol edin. Eğer ekranınızı
        kaplayan arka plan renginden farklı renkte bir nokta ya da noktalar
        tespit ederseniz ekranınızda <strong>ölü piksel</strong> ya da{" "}
        <span className="font-bold text-purple-600">takılmış piksel</span> var
        demekdir.
      </p>
      <blockquote>
        Ölü piksel testini sonlandırmak için{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        tuşuna basabilirsiniz.
      </blockquote>
      <p>
        Aşağıdaki <code>Ölü - Sıkışmış Piksel Örnekleri</code> düğmesine
        tıklayarak ölü piksellerle ya da takılmış piksellerle alakalı örnek
        resimleri görüntüleyebilirsiniz.
      </p>
      <OluPikselGaleri text="Ölü -Sıkışmış Piksel Örnekleri" />
      <h2>Ölü Piksel Nedir?</h2>
      <p>
        <strong>Ölü Piksel</strong> ekranınızda daha çok enerji almama nedeniyle
        çalışmayan pikseldir. Bu nedenle <strong>ölü pikseller</strong> genelde
        siyah renkte olurlar.
      </p>
      <p>
        <strong>Ölü pikseller</strong> çoğunlukla siyah renkte (ışıksız) olsalar
        da bazı ekranlarda beyaz renkte olabilirler.
      </p>
      <p>
        <strong>Ölü piksellerle</strong> ilgili daha fazla bilgi ve{" "}
        <strong>ölü piksel tamiri</strong> için aşağıdaki{" "}
        <code>Ölü Piksel Tamiri</code> düğmesine tıklayarak ilgili sayfaya
        gidebilirsiniz.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/olu-piksel-tamiri"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Ölü Piksel Tamiri</span>
        </Link>
      </div>

      <h2>Sıkışmış Piksel Nedir?</h2>
      <p className="lead">
        <strong>Ölü piksellerin</strong> aksine sıkışmış ( takılmış ) pikseller
        hala enerji almaktadır ve bu durum sıkışmış piksel tamirini daha kolay
        hale getirmektedir.
      </p>
      <p>
        <span className="font-bold text-purple-600">Sıkışmış pikseller</span>{" "}
        uzun süre aynı renkte kalma gibi nedenlerden ötürü daha çok{" "}
        <span className="text-red-600">kırmızı</span>,{" "}
        <span className="text-green-400">yeşil</span>,{" "}
        <span className="text-blue-600">mavi</span> gibi temel renklerde kalan,
        sürekli aynı rengi yansıtan piksellerdir.
      </p>
      <p>
        <strong>Takılmış piksellerle</strong> ilgili daha fazla bilgi ve{" "}
        <strong>sıkışmış piksel tamiri</strong> için aşağıdaki{" "}
        <code>Sıkışmış Piksel Tamiri</code> düğmesine tıklayarak ilgili sayfaya
        gidebilirsiniz.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/sikismis-piksel-tamiri"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Sıkışmış Piksel Tamiri</span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicatorTr />}
        endIndicator={
          <EndIndicatorTr
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default OluPikselTesti
