import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicatorTr = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="tebrikler">
          🎉
        </span>
        Ölü piksel testini tamamladınız
        <span role="img" aria-label="tebrikler">
          🎉
        </span>
      </p>
      <p className="lead">
        Ekranınızda ölü piksel çıkmamış olmasını temenni ederim.
      </p>
      <p className="lead">
        Testten çıkmak{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        tuşuna basabilirsiniz.
      </p>
      <div>
        <RedSolidButton
          text="Pencereyi Kapat"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Testi Başa Al" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicatorTr)
