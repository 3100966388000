import React, { memo } from "react"
import SvgRightIcon from "../../Icons/SvgRightIcon"
const StartIndicatorTr = () => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        Ölü piksel testini ekranın herhangi bir yerine tıklayarak ya da
        klavyenizin{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" /> tuşuna
        (sağ) basarak başlatabilirsiniz.
      </p>
      <p className="lead">
        Testten çıkmak{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        tuşuna basabilirsiniz.
      </p>
    </div>
  )
}
export default memo(StartIndicatorTr)
